<template>
<b-container class="bv-example-row top">
    <div id="topic" class="topic">
        <b-row>
            <b-col cols="7">
                <a-divider type="vertical" class="v-divider" />
                <span class="topicName">{{topics.topicName}}</span>
            </b-col>
            <b-col>
                <span class="seeAllBtn" v-if="topics.seeAllBtn" @click="goToPage(topics.type)">ดูทั้งหมด</span>
            </b-col>
        </b-row>
    </div>
</b-container>
</template>

<script>
import router from '../../router'
import {
    mapGetters
} from 'vuex'
export default {
    name: "TopicOnHomepage",
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    mounted() {
        this.checkMode()
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    props: ['topics'],
    methods: {
        goToPage(type) {
            if (type == 'allNovel') {
                router.push("allNovel")
            } else {
                router.push("/latestNovel/" + type)
            }
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('topic').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            document.getElementsByClassName('topic').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    }
}
</script>

<style scoped>
.v-divider {
    width: 3px;
    height: 20px;
    background: #EE806B;
}

.top {
    padding-left: 0px;
    padding-right: 0px;
}

.topic {
    background-color: #ffffff;
    width: 102%;
    padding: 20px;
    margin-top: 3%;
}

.topicName {
    font-size: 21px;
    font-weight: bold;
}

.seeAllBtn {
    color: white;
    float: right;
    background-color: #F5D087;
    border-radius: 6px;
    padding: 3px 25px 3px 25px;
    font-size: 16px;
}

.seeAllBtn:hover {
    background-color: #FAC762;
    cursor: pointer;
    font-weight: bold;
}

@media screen and (max-width: 500px) {
    .v-divider {
        height: 15px;
    }

    .topic {
        width: 100%;
        padding: 10px;
    }

    .topicName {
        font-size: 15px;
    }
}

.darkmode {
    background-color: #35363A;
}
</style>
